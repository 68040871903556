import React, { useState, useEffect } from 'react';
import { bindActionCreators } from "redux";
import { useDispatch, useSelector, connect } from 'react-redux';
import {
    loadSearchData,
    selectSearchData,
    selectValue,
    setTblOption,
    getTeamsList,
    selectTeamsList,
    setFilter,
    selectFilterBy,
    selectLoader,
    selectStaticWords,
    selectLanguageId,selectTblOptions,selectTblSelOpt,selectTblSelOptName
} from './homeSlice';
import ReactAutocomplete from 'react-autocomplete';
import { formatDate } from '../../sharedfiles/helper';
import { getEvenId, navigateToTournament, metaData } from '../../AppSlice';
import Loading from '../loading/Loading';
// import noImage from '../../assets/images/teams/no-img.png'


export function Home(props) {
    const [fetched, setFetched] = useState(false);
    const dispatch = useDispatch();
    const data = useSelector(selectSearchData);
    const value = useSelector(selectValue);
    const teamsList = useSelector(selectTeamsList);
    const filterBy = useSelector(selectFilterBy);
    const loader = useSelector(selectLoader);
    const staticKeyWords = useSelector(selectStaticWords);
    const langugageId = useSelector(selectLanguageId);
    const tblOptions=useSelector(selectTblOptions);
    const tblSelOpt =useSelector(selectTblSelOpt);
   const tblSelOptName=useSelector(selectTblSelOptName);
    useEffect(() => {
        if (fetched === false) {
            dispatch(setFilter(1));
            dispatch(loadSearchData([], '', 1, langugageId));
            setFetched(true);
            dispatch(metaData("Match"));
        }
    });

    return (
        <div className="mainLanding-page">

            <div className="mainSearchbar">
                <div className="mainSearchbar-leftSection">
                    <ul>
                        <li className={filterBy === 1 ? "active" : ""} onClick={() => { dispatch(setFilter(1)); dispatch(loadSearchData(teamsList, "", 1, langugageId)) }}>
                            <a href="#" ><i className="icon-clock icons"></i></a>
                        </li>
                        <li className={filterBy === 2 ? "active" : ""} onClick={() => { dispatch(setFilter(2)); dispatch(loadSearchData(teamsList, "", 2, langugageId)) }}>
                            <a href="#" ><i className="icon-earth icons"></i></a>
                        </li>
                        
                    </ul>
                </div>

                <div className="mainSearchbar-rightSection">
                <select value={tblSelOpt} onChange={(event)=>dispatch(setTblOption({val:event.target.value,id:null}))}>
                                    {tblOptions.map((opt, index) => {
                                        return <option key={index} value={opt.id}>{staticKeyWords[opt.name]}</option>
                                    })}
                        </select>
                    <div className="form-group form-inline">
                        <div className="form-control">
                            <ReactAutocomplete
                                getItemValue={(item) => item.teamName}
                                items={teamsList}
                                renderItem={(item, isHighlighted) =>
                                    <div key={item.itemId} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                                        {item.teamName}
                                    </div>
                                }
                                inputProps={{ placeholder: staticKeyWords.SearchByTeamName }}
                                value={value}
                                onChange={(e) => dispatch(getTeamsList(e.target.value, filterBy, langugageId))}
                                onSelect={(val) => dispatch(loadSearchData(teamsList, val, filterBy, langugageId))}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="contentBg">
                {data && data.length > 0 ? data.map(function (comp, index) {
                    var dateFormat = {};
                    if (filterBy === 1) {
                        dateFormat = formatDate(comp.label, 'home');
                    }
                    return <table className="tableDefault scrolling-wrapper" key={"tbl"+index}>
                        <thead>
                            <tr>
                                <th className="w-date" colSpan="2" onClick={() => dispatch(navigateToTournament(comp.id, props, filterBy, 'tournament'))}>{filterBy === 1 ? dateFormat.date : comp.label}</th>
                                <th className="w-fixture"></th>
                                {/* <th className="w-form">Form</th>
                                                <th className="w-winPredictor">Predictor</th> */}
                                <th className="w-form">
                                    {/* <select value={tblSelOpt} onChange={(event)=>dispatch(setTblOption({val:event.target.value,id:comp.id}))}>
                                    {tblOptions.map((opt, index) => {
                                        return <option key={index} value={opt.id}>{staticKeyWords[opt.name]}</option>
                                    })}
                                </select> */}
                                {staticKeyWords[tblSelOptName]}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                comp.events.map(function (event, eventIndex) {
                                    var kickOffDT = formatDate(event.kickOff, 'home');
                                    return <tr data-href="" key={eventIndex} >
                                        <td className="text-left w-date">
                                           {event.competitionIdNav!=null? <div onClick={() => dispatch(navigateToTournament(event.competitionId, props, filterBy, 'date'))}>{filterBy === 2 ? <><div>{kickOffDT.date}</div></> :
                                            <div>{event.competitionName ? event.competitionName : ""}</div>}</div>:<div>{filterBy === 2 ? <><div>{kickOffDT.date}</div></> : <div>{event.competitionName ? event.competitionName : ""}</div>}</div>}
                                        </td>
                                        <td>{kickOffDT.time}</td>
                                        <td onClick={() => {
                                            dispatch(getEvenId({
                                                countryId: event.countryId,
                                                competitionId: (filterBy == 1) ? event.competitionId : comp.id,
                                                eventId: event.eventId
                                            }, 'home', props))
                                        }}>
                                            <div className="table-fixture">{event.homeTeamName} </div>
                                            <div className="table-fixture">{event.awayTeamName}</div>
                                        </td>
                                        {tblSelOpt === 1 && <td>
                                            <div className="team-lastForm formWidth">
                                                {
                                                    event.homeForm.map(function (wl, wlIndex) {
                                                        return <span key={wlIndex} className={"status " + (wl.toLowerCase() === 'w' ? 'win' : wl.toLowerCase() === 'l' ? "loss" : wl.toLowerCase() === 'd' ? "draw" : "")}>{staticKeyWords[wl.toLowerCase()]}</span>
                                                    })
                                                }
                                            </div>
                                            <div className="team-lastForm formWidth">
                                                {
                                                    event.awayForm.map(function (wl, wlIndex) {
                                                        return <span key={wlIndex} className={"status " + (wl.toLowerCase() === 'w' ? 'win' : wl.toLowerCase() === 'l' ? "loss" : wl.toLowerCase() === 'd' ? "draw" : "")}>{staticKeyWords[wl.toLowerCase()]}</span>
                                                    })
                                                }
                                            </div>
                                        </td>}
                                        {tblSelOpt === 3 && <td>
                                            <div>
                                           {(event.hadHomePct &&event.hadAwayPct)?<> <div>{event.hadHomePct?(event.hadHomePct+'%'):""}</div>
                                                <div>{event.hadAwayPct?(event.hadAwayPct+'%'):""}</div></>:
                                                <div>-</div>}
                                            </div>
                                        </td>}
                                        {tblSelOpt === 2 &&<td>
                                        {event.bestBetName}{event.subMarketName && event.subMarketName.length>0?`[${event.subMarketName}]`:""} - {event.outcomeName}
                                        </td>}
                                    </tr>
                                })
                            }

                        </tbody>
                    </table>
                }) : <Loading isLoader={loader} isNoData={!loader && !data.length} temporarilyNoData={staticKeyWords.temporarilyNoData} />}
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        filterBy: state.home.filterBy
    };
}
function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        actions: bindActionCreators({

        }, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Home);